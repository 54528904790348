<template>

    <div style="background-color: white;">
    <p><br></p>
    <div style="position: sticky;top: 0;background-color: white;padding-bottom: 10px;">
    <p dir="ltr" style="line-height:1.38;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:15pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DATA PRIVACY NOTICE</span></p>
    <p dir="ltr" style="line-height:1.38;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Online Teaching Efficiency Rating (TER)</span></p>
    </div>
    <p><br></p>

    
    <p></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TER II: FACULTY ( PEER TO PEER EVALUATION) </span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#073763;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Data Privacy Policy</span></p>
    <p></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Personal Information being collected:</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The collection of the faculty members&rsquo; (as a rater) personal data will be done through the online Teaching Efficiency Rating (TER) system. The following personal information will be collected: full name, email address, evaluation comments and evaluation rating.&nbsp;</span></p>
    <p></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Purpose of Collection and Processing:</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The collection of the personal information will be used to administer the Peer to Peer Evaluation as part of the Teaching Efficiency Rating evaluation process.&nbsp;</span></p>
    <p></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Processing and Use of the collected personal information</span></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The collected personal information will be used in the following manner:&nbsp;</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr" style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-style:normal;font-variant:normal;text-decoration:none;">
            <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The personal information will be used to administer the Peer to Peer Evaluation.&nbsp;</span></p>
        </li>
        <li aria-level="1" dir="ltr" style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-style:normal;font-variant:normal;text-decoration:none;">
            <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The rater&rsquo;s personal information such as their full name and email address will not be included in the computation of the efficiency rating and will not be disclosed to the faculty member being evaluated.&nbsp;</span></p>
        </li>
        <li aria-level="1" dir="ltr" style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-style:normal;font-variant:normal;text-decoration:none;">
            <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The rater&rsquo;s evaluation comments and individual rating will be used to generate the teaching efficiency rating report.</span></p>
        </li>
        <li aria-level="1" dir="ltr" style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-style:normal;font-variant:normal;text-decoration:none;">
            <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The generated TER report will contain aggregate information and will not include the raters&rsquo; personal information, such as their name, email address and individual rating.&nbsp;</span></p>
        </li>
    </ul>
    <p></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Transfer and Storing of Personal Information</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr" style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-style:normal;font-variant:normal;text-decoration:none;">
            <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">All collected personal information will be stored in Google Cloud and only the authorized OVCAA personnel will have access to the accounts.&nbsp;</span></p>
        </li>
        <li aria-level="1" dir="ltr" style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-style:normal;font-variant:normal;text-decoration:none;">
            <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Only the authorized OVCAA personnel will be allowed to generate a hardcopy report from the Online TER System. The hardcopy report will be stored in a secured filing cabinet and can be accessed only by the authorized OVCAA personnel.&nbsp;</span></p>
        </li>
        <li aria-level="1" dir="ltr" style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-style:normal;font-variant:normal;text-decoration:none;">
            <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Both the soft and hard copy of TER results are subject for storage; such documents may be used for future reference, such as for performance evaluation purposes.</span></p>
        </li>
    </ul>
    <p></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Retention of collected information</span></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">MSU-General Santos takes reasonable steps to ensure that the personal information collected and processed is reliable, accurate and complete for its intended purpose as described. The personal information will be retained for the period necessary to fulfill the purpose outlined in this Privacy Policy.&nbsp;</span></p>
    <p></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#980000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Faculty Data Privacy agreement</span></p>
    <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">By clicking the AGREE button, I acknowledge that I have read and agreed the Data Privacy Policy&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">and express my consent to the Online TER System to collect and process my personal data in relation to the purpose of evaluation related processing/internal, lawful and non-commercial use to the University. I hereby affirm my right to: (a) be informed; (b) object to processing; (c) access; (d) rectify, suspend or withdraw my personal data; (e) damages; and (f) data portability pursuant to the provisions of the Act and its corresponding Implementing Rules and Regulations.</span></p>
    <p></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:9pt;font-family:Arial;color:#20124d;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For more concerns regarding Data Privacy Policy,kindly contact our DPO:thru this line: +63 998 510 5736 or send an email to: dpo@msugensan.edu.ph</span></p>
    </div>
    </template>

<script>
export default {
    name: 'PeerNotice',
    props:{
        label:String
    },          
    data: () => ({
        dialog:true
    }),
}
</script>
